import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import { fingerScan } from "careplix-web-algo";

import LoadingScreen from "../components/LoadingScreen";

import Failure from "../assets/images/failure.gif";
import HertBeat from "../assets/images/heartbeat.gif";
import Analyzing from "../assets/images/analyzing.gif";
import { ReactComponent as WifiSignal } from "../assets/icons/wifi-square.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as RightArrow } from "../assets/icons/right-arrow.svg";

const FingerScan = () => {
  const { t } = useTranslation();
  const [analyzing, setAnalyzing] = useState(false);
  const [error, setError] = useState("");
  const [scanFrameData, setScanFrameData] = useState({
    type: "",
    percentage: 0,
    timeElapsed: 0,
    confidence: 0,
    fps: 0,
  });

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state.scan_token?.length > 0) {
      fingerScan.onFrame((fd) => setScanFrameData(fd));
      fingerScan.onScanFinish(
        async ({ raw_intensity, ppg_time, average_fps }) => {
          try {
            setAnalyzing(true);
            const resp = await fetch(
              "https://sdk-staging.uae.careplix.com/vitals/add-scan",
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  employee_id: state.partner_client_id,
                  api_key: state.partner_key,
                  scan_token: state.scan_token,
                  posture: state.posture,
                  dob: state.dob,
                  gender: state.gender,
                  metadata: {
                    physiological_scores: {
                      height: state.height,
                      weight: state.weight,
                    },
                    device: `RPPG_CAREPLIX_FINGER_${
                      navigator.platform.match(/iPhone|iPod|iPad/)
                        ? "IOS"
                        : "ANDROID"
                    }`,
                    ppg_time: ppg_time,
                    raw_intensity: raw_intensity,
                    fps: average_fps,
                  },
                }),
              }
            );
            const resp_json = await resp.json();
            if (resp_json.statusCode?.toString().startsWith("2")) {
              if (state.is_webview) {
                window.parent?.postMessage?.(
                  `scan-result:::${JSON.stringify(resp_json)}`,
                  "*"
                );
                window.ReactNativeWebView?.postMessage?.(
                  `scan-result:::${JSON.stringify(resp_json)}`
                );
              }
              if (state.show_result)
                navigate("/scan-result", {
                  state: { ...state, result: resp_json },
                  replace: true,
                });
              else
                window.location.replace(
                  new URL(
                    `${state.callback_url}${
                      state.callback_url.slice(-1) !== "/" ? "/" : ""
                    }vitals-scan-result?${new URLSearchParams({
                      data: JSON.stringify(resp_json),
                    })}`
                  )
                );
            } else throw new Error(resp_json.message);
          } catch (err) {
            console.error(err);
            setError(err.message);
          }
        }
      );
      fingerScan.onError((err) => {
        console.error(err);
        setError(err.message);
      });
      fingerScan
        .startScan(60000, 60000, 20000)
        .then(() => console.log("Scan Started"))
        .catch(console.error);
    } else setError("Invalid Scan Token");
    return () => {
      fingerScan.stopScan(true);
    };
  }, [navigate, state]);

  const scanMSGIndexList = useMemo(
    () => Array.from(Array(10).keys()).sort(() => Math.random() - 0.5),
    []
  );

  const currentMessage = useMemo(() => {
    const timeElapsed = scanFrameData.timeElapsed / 1000;
    if (timeElapsed <= 6) return t("GetMessage1");
    else if (timeElapsed <= 12) return t("GetMessage2");
    else if (timeElapsed <= 17) return t("GetMessage3");
    else if (timeElapsed <= 18) return t("GetMessageCD", { countDown: 3 });
    else if (timeElapsed <= 19) return t("GetMessageCD", { countDown: 2 });
    else if (timeElapsed <= 20) return t("GetMessageCD", { countDown: 1 });
    else if (timeElapsed <= 26) return t(`ScanMessage${scanMSGIndexList[0]}`);
    else if (timeElapsed <= 32) return t(`ScanMessage${scanMSGIndexList[1]}`);
    else if (timeElapsed <= 38) return t(`ScanMessage${scanMSGIndexList[2]}`);
    else if (timeElapsed <= 44) return t(`ScanMessage${scanMSGIndexList[3]}`);
    else if (timeElapsed <= 50) return t(`ScanMessage${scanMSGIndexList[4]}`);
    else if (timeElapsed <= 56) return t(`ScanMessage${scanMSGIndexList[5]}`);
    else if (timeElapsed <= 62) return t(`ScanMessage${scanMSGIndexList[6]}`);
    else if (timeElapsed <= 68) return t(`ScanMessage${scanMSGIndexList[7]}`);
    else if (timeElapsed <= 74) return t(`ScanMessage${scanMSGIndexList[8]}`);
    else return t(`ScanMessage${scanMSGIndexList[9]}`);
  }, [t, scanMSGIndexList, scanFrameData.timeElapsed]);

  return (
    <div className="relative h-screen bg-black">
      {error.length > 0 ? (
        <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
          <img src={Failure} alt="failure icon" className="w-48 mx-auto" />
          <p className="mt-3 text-[#0D212C] text-center whitespace-pre-line">
            {t("ScanFailed")}
          </p>
          <button
            className="mt-8 px-4 py-2 rounded-lg bg-[#F05252] text-white text-sm font-medium"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("GoBack")}
          </button>
        </div>
      ) : analyzing ? (
        <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
          <img src={Analyzing} alt="analyzing icon" className="w-56 mx-auto" />
          <p className="mt-8 text-[#0D212C] text-base font-bold">
            {t("AnalyzingData")}
          </p>
          <p className="mt-2 text-[#6E7A80] text-xs">
            {t("AnalyzingDataDescription")}
          </p>
        </div>
      ) : (
        <>
          <div className="relative h-full w-full">
            <video
              className="fixed top-24 ltr:left-8 rtl:right-8 w-px h-px bg-white/80"
              id="videoInput"
              autoPlay
              muted
              playsInline
            />
            <canvas id="canvasOutput" className="h-full w-full -scale-x-100" />
            <div className="fixed inset-x-0 top-[25vh]">
              {fingerScan.isFingerInView() ? (
                <img src={HertBeat} alt="heartbeat" className="w-48 mx-auto" />
              ) : (
                <>
                  <svg className="w-48 mx-auto" viewBox="0 0 300 90">
                    <circle fill="#fff" cx="278.63" cy="55.48" r="13.33" />
                    <polyline
                      fill="none"
                      stroke="#fff"
                      strokeWidth={5}
                      strokeMiterlimit={10}
                      points="7.75,54.04 74.69,54.04 83.58,21.09 96.13,72.86 102.93,54.04 118.61,53.51 130.12,10.11 158.36,80.71 169.86,54.04 189.73,54.04 198.1,23.18 200.71,23.18 212.22,68.68 217.45,54.04 265.57,53.96"
                    />
                  </svg>
                  <p className="mt-2 text-xl text-center text-white drop-shadow">
                    {t("FingerDetectionError")}
                  </p>
                </>
              )}
            </div>
            <div
              className="fixed top-16 ltr:right-6 rtl:left-6 flex items-center gap-1 px-2 py-1 rounded-full bg-[#F05252] text-white"
              style={{
                backgroundColor:
                  scanFrameData.confidence > 0.5
                    ? scanFrameData.confidence > 0.9
                      ? "#27C178"
                      : "#FFA609"
                    : "#F05252",
              }}
            >
              <WifiSignal className="shrink-0 h-3 w-3" />
              <p className="text-xs font-semibold">
                {t("Signal")}&nbsp;
                {scanFrameData.confidence > 0.5
                  ? scanFrameData.confidence > 0.9
                    ? t("Good")
                    : t("Moderate")
                  : t("Poor")}
              </p>
            </div>
            <button
              type="button"
              onClick={() => {
                fingerScan.stopScan(true);
                navigate(-1);
              }}
              className="fixed top-8 ltr:left-6 rtl:right-6 flex items-center gap-2 text-white text-sm font-semibold"
            >
              <Close className="shrink-0 h-6 w-6" />
              <span>{t("FingerScanning")}</span>
            </button>
            <div className="fixed bottom-20 inset-x-6 p-4 rounded-lg shadow-sm border border-white/20 bg-white/10">
              <h3 className="text-white font-bold text-lg leading-tight">
                {scanFrameData.type === "scan"
                  ? t("PercentageCompleted", {
                      percentage: scanFrameData.percentage,
                    })
                  : t("CalibrationProgress")}
              </h3>
              <h4 className="mt-1.5 text-[#9EA6AB] text-sm leading-snug">
                {currentMessage}
              </h4>
            </div>
            <button
              type="button"
              onClick={() => {
                fingerScan.stopScan(true);
                navigate(-1);
              }}
              className="fixed bottom-12 ltr:left-6 rtl:right-6 flex items-center gap-1 text-[#F05252] text-base font-semibold"
            >
              <span>{t("CancelScan")}</span>
              <RightArrow className="shrink-0 h-4 w-4" />
            </button>
          </div>
          {fingerScan.isInitializing() && (
            <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
              <LoadingScreen />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FingerScan;
