import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar"],
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    resources: {
      en: {
        translation: {
          Wellness: "Wellness",
          Proceed: "Proceed",
          Next: "Next",
          GoBack: "Go Back",
          Disclaimer: "Disclaimer",
          DisclaimerDescription:
            "For Investigational Use Only. This scan is not a substitute for the clinical judgment of a healthcare professional. This scan is intended to improve your awareness of general wellness. This scan does not diagnose, treat, mitigate or prevent any disease, symptom, disorder or abnormal physical state. Consult with a healthcare professional or emergency services if you believe you may have a medical issue.",

          BasicDetails: "Basic Details",
          DOB: "Date of Birth",
          Height: "Height (in cms)",
          Weight: "Weight (in kgs)",
          Gender: "Gender",
          male: "Male",
          female: "Female",
          InvalidDOB: "Invalid Date of Birth",
          AgeError: "Age must be between 18 and 110",
          InvalidGender: "Invalid Gender",
          InvalidHeight: "Invalid Height",
          HeightError: "Height must be between 30 and 250 cms",
          InvalidWeight: "Invalid Weight",
          WeightError: "Weight must be between 20 and 220 kgs",

          SelectScanType: "Select Scan Type",
          faceScan: "Face Scan",
          FaceScanDescription:
            "60-seconds contactless vital scan using your smartphone's front camera. It is as simple as taking a selfie.",
          fingerScan: "Finger Scan",
          FingerScanDescription:
            "Place your finger on the rear camera of your smartphone to scan your vital signs in 60 seconds.",
          StartScan: "Start Scan",

          SelectPosture:
            "Please select the posture you were in immediately prior to the assessment:",
          resting: "Resting",
          standing: "Standing",
          walking: "After Walking",
          exercising: "After Running",
          PostureNote:
            "Please select the option that best describes your posture before the assessment. This information will help us to better understand your physical state during the assessment.",
          Note: "Note:\nYour posture should be at resting while doing the scan.",

          faceScanQuestion: "How should I prepare for a Face scan?",
          fingerScanQuestion: "How should I prepare for a Finger scan?",
          FollowSteps: "Follow these steps:",
          faceInstruction1:
            "The ambient light should be bright and consistent and must be white.",
          faceInstruction2:
            "Remove your masks, eye-wear, or clothing obstruction the face for the duration of scan.",
          faceInstruction3:
            "Keep your face and phone steady to avoid movements during the scan.",
          faceInstruction4:
            "For light mode: Make sure your face is mostly covered by the blue circle.",
          faceInstruction5: "If prompted, allow the camera permission.",
          fingerInstruction1:
            "Hold your mobile device in a fixed position throughout the measurement.",
          fingerInstruction2:
            "Place your index finger fully over the rear camera lens without covering the flashlight.",
          fingerInstruction3: "Apply moderate and constant pressure.",
          fingerInstruction4: "Avoid moving or talking during the measurement.",
          fingerInstruction5:
            "Ensure there is some light; measurements should not be taken in complete darkness.",

          FaceScanning: "Face Scanning",
          FingerScanning: "Finger Scanning",
          CalibrationProgress: "Calibration in progress...",
          PercentageCompleted: "{{percentage}}% Completed",
          Signal: "Signal:",
          Good: "Good",
          Moderate: "Moderate",
          Poor: "Poor",
          FaceDetectionError: "Cannot Detect Face",
          FingerDetectionError: "Cannot Detect Finger",
          CancelScan: "Cancel Scan",
          AnalyzingData: "Analyzing Data",
          AnalyzingDataDescription:
            "Hold tight, the measurement results are on the way",
          ScanFailed: "Scan Failed!\nPlease try again.",
          GetMessage0: "Keep your head upright and look straight at the camera",
          GetMessage1: "Cover your back camera with your finger",
          GetMessage2: "During the measurement, please do not speak or move",
          GetMessage3:
            "Keep your device steady throughout the vital measurement",
          GetMessageCD: "We are good to start in {{countDown}}",
          ScanMessage0:
            "We burn calories while we are asleep because brain activity requires energy",
          ScanMessage1:
            "Exercise promotes cardiovascular health, ensuring more blood and oxygen to circulate the body, helping to elevate energy",
          ScanMessage2:
            "Always sleep on your back straight since it allows your neck and spine in a neutral position",
          ScanMessage3:
            "Alcohol consumption disrupts chemical impulses between brain cells causing impulsive behaviour, slurred speech, impaired memory",
          ScanMessage4:
            "Meditation increases nitric oxide generation in the brain helping to lower your blood pressure",
          ScanMessage5:
            "Meditation reduces the production of cytokines in several recent studies, thus helping in stress reduction",
          ScanMessage6:
            "Laughing is good for your heart. It reduces stress and gives a boost to your immune system",
          ScanMessage7:
            "Lack of sleep can lead to heart disease, heart attack, or stroke",
          ScanMessage8: "Heart pumps about 2,000 gallons of blood every day",
          ScanMessage9: "Your heart will beat about 115,000 times each day",
        },
      },
      ar: {
        translation: {
          Wellness: "الصحة",
          Proceed: "متابعة",
          Next: "التالي",
          GoBack: "رجوع",
          Disclaimer: "تنبيه",
          DisclaimerDescription:
            "للاستخدام البحثي فقط. هذا الفحص ليس بديلاً عن القرار السريري لمتخصص الرعاية الصحية. يهدف هذا الفحص إلى تحسين وعيك بالصحة العامة. هذا الفحص لا يشخص أو يعالج أو يخفف أو يمنع أي مرض أو أعراض أو اضطراب أو حالة جسدية غير طبيعية. استشر متخصص في الرعاية الصحية أو خدمات الطوارئ إذا كنت تعتقد أنك قد تواجه مشكلة طبية.",

          BasicDetails: "تفاصيل أساسية",
          DOB: "تاريخ الميلاد",
          Height: "الطول (بالسنتيمترات)",
          Weight: "الوزن (بالكيلوغرامات)",
          Gender: "الجنس",
          male: "ذكر",
          female: "أنثى",
          InvalidDOB: "تاريخ ميلاد غير صحيح",
          AgeError: "يجب أن يكون العمر بين 18 و 110",
          InvalidGender: "جنس غير صحيح",
          InvalidHeight: "طول غير صحيح",
          HeightError: "يجب أن يكون الطول بين 30 و 250 سم",
          InvalidWeight: "وزن غير صحيح",
          WeightError: "يجب أن يكون الوزن بين 20 و 220 كغم",

          SelectScanType: "اختر نوع الفحص",
          faceScan: "فحص الوجه",
          FaceScanDescription:
            'فحص حيوي بدون تلامس مدة 60 ثانية باستخدام الكاميرا الأمامية لهاتفك الذكي. الأمر بسيط كالتقاط صورة "سيلفي".',
          fingerScan: "فحص البصمة",
          FingerScanDescription:
            "ضع إصبعك على الكاميرا الخلفية لهاتفك الذكي لفحص علاماتك الحيوية في 60 ثانية.",
          StartScan: "ابدأ الفحص",

          SelectPosture:
            "يرجى اختيار الوضعية التي كنت فيها مباشرة قبل التقييم:",
          resting: "راحة",
          standing: "وقوف",
          walking: "بعد المشي",
          exercising: "بعد الجري",
          PostureNote:
            "يرجى تحديد الخيار الذي يعطي الوصف الأفضل لوضعيتك قبل التقييم. تساعدنا هذه المعلومات على فهم أفضل لحالتك الجسدية أثناء التقييم.",
          Note: "ملاحظة:\nينبغي أن تكون وضعيتك في حالة راحة أثناء إجراء الفحص.",

          faceScanQuestion: "كيف أستعد لفحص الوجه؟",
          fingerScanQuestion: "كيف أستعد لفحص البصمة؟",
          FollowSteps: "اتبع هذه الخطوات:",
          faceInstruction1:
            "ينبغي أن يكون الضوء المحيط ساطعًا ومتسقًا وأبيض اللون.",
          faceInstruction2:
            "انزع أي أقنعة أو نظارات أو ملابس تعيق الوجه أثناء مدة الفحص.",
          faceInstruction3:
            "حافظ على ثبات وجهك وهاتفك لتجنب الحركة أثناء الفحص.",
          faceInstruction4:
            "وضع الضوء: تأكد من أن معظم وجهك مغطى بالدائرة الزرقاء.",
          faceInstruction5: 'إذا طُلب منك، فاختر "السماح" لاستخدام للكاميرا.',
          fingerInstruction1: "أبقِ جهازك المحمول في وضع ثابت أثناء القياس.",
          fingerInstruction2:
            "ضع إصبعك السبابة بالكامل على عدسة الكاميرا الخلفية دون تغطية ضوء الفلاش.",
          fingerInstruction3: "اضغط ضغطًا معتدلًا وثابتًا.",
          fingerInstruction4: "تجنب الحركة أو التحدث أثناء القياس.",
          fingerInstruction5:
            "تأكد من وجود بعض الضوء؛ فلا ينبغي أخذ القياسات في ظلام تام.",

          FaceScanning: "مسح الوجه",
          FingerScanning: "مسح البصمة",
          CalibrationProgress: "المعايرة جارية...",
          PercentageCompleted: "نسبة اكتمال {{percentage}}%",
          Signal: "الإشارة:",
          Good: "جيد",
          Moderate: "متوسط",
          Poor: "ضعيف",
          FaceDetectionError: "لا يمكن التعرف على الوجه",
          FingerDetectionError: "لا يمكن التعرف على البصمة",
          CancelScan: "إلغاء الفحص",
          AnalyzingData: "تحليل البيانات",
          AnalyzingDataDescription: "يرجى الانتظار، نتائج القياس في الطريق",
          ScanFailed: "إخفاق الفحص!\nيرجى المحاولة مرة أخرى",
          GetMessage0: "أبقِ رأسك مستقيمًا وانظر مباشرة إلى الكاميرا",
          GetMessage1: "قم بتغطية الكاميرا الخلفية بإصبعك",
          GetMessage2: "أثناء القياس، يرجى عدم التحدث أو الحركة",
          GetMessage3: "حافظ على ثبات جهازك أثناء القياس الحيوي",
          GetMessageCD: "نحن جاهزون للبدء في {{countDown}}",
          ScanMessage0:
            "نحرق السعرات الحرارية أثناء النوم لأن نشاط الدماغ يحتاج إلى الطاقة.",
          ScanMessage1:
            "ممارسة التمارين تعزز صحة القلب والأوعية الدموية، مما يضمن تدفق مزيد من الدم والأكسجين في الجسم ويساعد على رفع مستوى الطاقة.",
          ScanMessage2:
            "نم دائمًا على ظهرك بوضع مستقيم لأنه يسمح بوضعية محايدة لرقبتك وعمودك الفقري.",
          ScanMessage3:
            "استهلاك الكحول يعطل النبضات الكيميائية بين خلايا الدماغ، مما يسبب الاندفاعية في السلوك، والتلعثم في الكلام، وضعف الذاكرة.",
          ScanMessage4:
            "التأمل يزيد من توليد أكسيد النيتريك في الدماغ مما يساعد على خفض ضغط الدم.",
          ScanMessage5:
            "التأمل يقلل من إنتاج السيتوكينات بحسب دراسات حديثة عديدة، ومن ثَمَّ فهو يساعد على تقليل التوتر.",
          ScanMessage6:
            "الضحك مفيد لقلبك، فهو يقلل التوتر ويعزز جهازك المناعي.",
          ScanMessage7:
            "قلة النوم قد تؤدي إلى أمراض القلب أو النوبات القلبية أو السكتة الدماغية.",
          ScanMessage8: "يضخ القلب حوالي 2000 غالون من الدم يوميًا.",
          ScanMessage9: "ينبض قلبك حوالي 115,000 مرة يومًا.",
        },
      },
    },
  });

export default i18n;
